import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  Renderer2,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { Template } from 'altru-types';
import {
  CardCTAClickedEvent,
  CardLikedEvent,
  CardSelectedEvent,
  CardSharedEvent,
  EMPTY_CARD,
  UiCard,
} from './card-types';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnChanges {
  @Input()
  card: UiCard = EMPTY_CARD;

  @Input()
  tabbable = false;

  @Input()
  template: Template;

  @Input()
  highlightText = '';

  @Output()
  selected = new EventEmitter<CardSelectedEvent>();

  @Output()
  liked = new EventEmitter<CardLikedEvent>();

  @Output()
  shared = new EventEmitter<CardSharedEvent>();
  @Input() selectedCard: any;
  @Output()
  ctaClicked = new EventEmitter<CardCTAClickedEvent>();
  @ViewChild('cardElement', { static: true }) cardElement: ElementRef; // Reference to the card element
  routerSubscription: any;
  constructor(
    private renderer: Renderer2,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef
  ) {}

  onClick(event: MouseEvent, card: UiCard) {
    event.preventDefault();
    event.stopPropagation();
    this.selected.emit({ event, card });
  }
  highlightCard(changes: SimpleChanges) {
    const selectedCardId = Number(changes.selectedCard.currentValue);
    if (selectedCardId === this.card.id) {
      this.renderer.addClass(this.cardElement.nativeElement, 'focused-card');
      this.cardElement.nativeElement.focus();
      setTimeout(() => {
        this.sharedService.updateSelectedCard(selectedCardId);
      }, 0);
      this.cd.detectChanges();
    }
  }
  onKeyup(event: KeyboardEvent, card: UiCard) {
    if ((event.key === 'Enter' || event.key === ' ') && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      this.selected.emit({ event, card });
      this.sharedService.updateSelectedCard(card.id);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedCard) {
      this.highlightCard(changes);
    }
  }

  onShared(event: CardSharedEvent): void {
    this.shared.emit(event);
  }

  onLiked(event: CardLikedEvent): void {
    this.liked.emit(event);
  }

  onCtaClicked(event: CardCTAClickedEvent): void {
    this.ctaClicked.emit(event);
  }
}
