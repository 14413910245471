import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { transcriptionsDictionary } from 'altru-types/@types';
import { VideoStoppedOutput } from './video-player.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'altru-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  @Input() autoplay = false;
  @Input() src = '';
  @Input() poster = '';
  @Input() transcript = '';
  @Input() originalVideoSize = '';
  @Input() backgroundColor = '';
  @HostBinding('class.embedded')
  @Input()
  embedded = false;
  firstTimePlayed = false;
  lastTime = 0;
  timeListenerID = 0;
  currentTime = 0;
  playingHandler;
  pauseHandler;
  endedHandler;
  subscription: Subscription = new Subscription();
  @Input() duration;
  @Input() ccUrls: transcriptionsDictionary;
  @Input() exportedVideo;
  @Output() videoPlayed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() videoStopped: EventEmitter<VideoStoppedOutput> = new EventEmitter<
    VideoStoppedOutput
  >();
  audioContext: AudioContext | null = null;
  videoElement: HTMLVideoElement;
  isUserInteraction = false;
  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    document.body.addEventListener('click', () => {
      this.isUserInteraction = true;
      this.setupAudioContext();
    });
    setTimeout(() => {
      const shadowRoot = this.videoPlayer?.nativeElement?.shadowRoot;
      this.videoElement = shadowRoot?.querySelector('video');

      if (!this.videoElement) {
        return;
      }

      // Store and bind event handlers
      const handlers = {
        playing: this.playing.bind(this),
        pause: this.pause.bind(this),
        ended: this.ended.bind(this),
      };

      // Attach event listeners dynamically
      Object.entries(handlers).forEach(([event, handler]) =>
        this.videoElement.addEventListener(event, handler)
      );
    }, 500); // Delay to ensure Shadow DOM is ready
    this.setupVideoElement();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  setupAudioContext(): void {
    if (this.isUserInteraction && this.videoElement) {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.AudioContext)();
        const audioSource = this.audioContext.createMediaElementSource(
          this.videoElement
        );
        const gainNode = this.audioContext.createGain();
        audioSource.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
        gainNode.gain.value = 2;
      }
    }
  }
  timeUpdate(): void {
    this.ngZone.runOutsideAngular(() => {
      this.ngZone.run(() => {
        this.currentTime = this.videoElement.currentTime;
      });
      if (this.videoElement.currentTime > 0) {
        this.ngZone.run(() => {
          this.lastTime = this.videoElement.currentTime;
        });
      }
      this.timeListenerID = window.requestAnimationFrame(
        this.timeUpdate.bind(this)
      );
    });
  }
  startListen(): void {
    if (!this.timeListenerID) {
      this.timeListenerID = window.requestAnimationFrame(
        this.timeUpdate.bind(this)
      );
    }
    this.lastTime = this.videoElement?.currentTime;
  }
  stopListen(): void {
    if (this.timeListenerID) {
      window.cancelAnimationFrame(this.timeListenerID);
      this.timeListenerID = 0;
    }
  }
  playing(): void {
    this.videoPlayed.emit(this.firstTimePlayed);
    this.firstTimePlayed = true;
    this.startListen();
  }
  ended(): void {
    this.stopListen();
  }
  play(): void {
    this.videoElement.play();
  }

  pause(): void {
    this.stopListen();
    this.videoElement.pause();
    this.trackVideoStopped();
  }

  trackVideoStopped(): void {
    this.videoStopped.emit({
      duration: this.duration,
      lastTime: this.lastTime,
    });
  }
  setupVideoElement(): void {
    if (this.exportedVideo) {
      const shadowRoot = this.videoPlayer.nativeElement.shadowRoot;
      this.videoElement = shadowRoot
        ? shadowRoot.querySelector('video')
        : this.videoPlayer.nativeElement.querySelector('video');
    }
  }
}
